import React from 'react';
import Background from '../../components/background';
import Layout from '../../components/layout';

export default function Imprint() {
  return (
    <>
      <Background />
      <Layout>
        <div className="relative overflow-hidden bg-gray-800 mb-10">
          <div className="relative px-4 sm:px-6 lg:px-8">
            <div className="prose prose-lg h-full prose-white mx-auto mt-6 text-gray-300">
              <h1 className="text-3xl text-gray-300">Imprint</h1>
              <div>
                <h2 className="text-xl text-gray-300">
                  Information according to &sect;5 TMG
                </h2>
              </div>
              <p>
                <b>Kevin Kraus - Webdesign &amp; Content Creation</b>
                <br />
                Münsterstraße 18
                <br />
                22529 Hamburg
              </p>
              <p className="prose prose-lg prose-a:text-gray-100">
                <a href="mailto:hello@kevinkrs.com">
                  Email: hello@kevinkrs.com
                </a>
              </p>
              <h3 className="text-gray-200">Disclaimer of liability</h3>
              <p className="text-gray-300">
                <b>Liability for links</b>
                <br />
                Our offer contains links to external third-party websites over
                whose content we have no influence. Therefore, we cannot accept
                any liability for this third-party content. The respective
                provider or operator of the pages is always responsible for the
                content of the linked pages. The linked pages were checked for
                possible legal violations at the time they were linked. No
                illegal content was found at the time the link was created. A
                permanent control of the content of the linked pages is not
                reasonable without concrete evidence of a violation of the law.
                As soon as we become aware of legal violations, we will remove
                such links immediately.
              </p>
              <p>
                <b>Liability for content</b>
                <br />
                As a service provider, we are responsible for our own content on
                these pages in accordance with general law in accordance with
                Section 7 (1) of the German Telemedia Act (TMG). According to
                &sect;&sect; 8 to 10 TMG, as a service provider, we are not
                obliged to monitor transmitted or stored third-party information
                or to investigate circumstances that indicate illegal activity.
                Obligations to remove or block the use of information according
                to general laws remain unaffected. However, liability in this
                regard is only possible from the point in time at which we
                become aware of a specific legal violation. As soon as we become
                aware of such legal violations, we will remove this content
                immediately.
              </p>
              <p>
                <b>Copyright</b>
                <br />
                The content and works on these pages created by the website
                operator are subject to German copyright law. The duplication,
                processing, distribution and any kind of exploitation outside
                the limits of copyright law require the written consent of the
                respective author or creator. Downloads and copies of this
                website are only permitted for private, non-commercial use.
                Insofar as the content on this site was not created by the
                operator, the copyrights of third parties are observed. In
                particular contents of third parties are marked as such. If you
                should nevertheless become aware of a copyright infringement, we
                would ask you to notify us accordingly. As soon as we become
                aware of legal violations, we will remove such content
                immediately.
              </p>
              <div></div>
              <div className=""></div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}
